import React from 'react'
import { motion } from 'framer-motion';
import ResumeCard from './ResumeCard';

const Education = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      {/* part one */}
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2001 - 2023</p>
          <h2 className="text-3xl md:text-4xl font-bold">Job Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
              title="Front-End Developer"
              subTitle="Egotec AG - (2022 - Present)"
              result="Germany"
              des="Development, support and implementation of new modules for websites on own EgoCMS system."
          />
          <ResumeCard
              title="Full stack developer (Wordpress)"
              subTitle="Rocque LTD - (2020 - 2022)"
              result="Ukraine"
              des="Development and support of websites. Client support. Development of additional modules and plugins."
          />
          <ResumeCard
              title="Front-End Developer"
              subTitle="Freelance - (2011 - 2020)"
              result="Ukraine"
              des="Working with Clients as a freelancer on Upwork. (Wordpress, Joomla, OpenCart, Landing-pages...)"
          />
          <ResumeCard
              title="Director of the Business Development Directorate at the Bank"
              subTitle="Banks - (2001 - 2011)"
              result="Ukraine"
              des="Both current activities' coordination and control of branches and divisions' employees: business development, operational and non-operational support"
          />
        </div>
      </div>
      {/* part Two */}
      <div className="mobile-education">
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">1995 - 2023</p>
          <h2 className="text-3xl md:text-4xl font-bold">Education Quality</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Fullstack Developer"
            subTitle="AIT TR technology school "
            result="Jan, 2023 - Sep, 2023"
            des="Stack of technologies: HTML, CSS, JavaScript, React, JAVA, SQL, MongoDB."
          />
          <ResumeCard
            title="German - B1 level"
            subTitle="VSH – German language school"
            result="Sep. 2022 - May 2023"
            des="Studying German and passing the national exam. Got a certificate B-1"
          />
          <ResumeCard
            title="Economist-mathematician"
            subTitle="Kryvyi Rih Technical University"
            result="1995 - 2000"
            des="Completed higher education at the university. Graduated from the Faculty of Economics. Received 2 diplomas: bachelor and specialist!"
          />
        </div>
      </div>


    </motion.div>
  );
}

export default Education