import React from 'react'
import {
  FaReact,
  FaInstagramSquare,
  FaTelegram,
  FaWhatsappSquare, FaHtml5, FaCss3, FaWordpress, FaJsSquare, FaJoomla, FaFigma, FaGithub, FaLinkedin
} from "react-icons/fa";
const Media = () => {
  return (
    <div className="flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between">
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            Find me in
          </h2>
          <div className="flex gap-4">
            <span className="bannerIcon" title="Telegram">
              <a href="https://t.me/golikandrii" target="_blank" rel="noreferrer">
                <FaTelegram />
              </a>
            </span>
            <span className="bannerIcon" title="Instagram">
              <a href="https://www.instagram.com/golikandrey/" target="_blank" rel="noreferrer">
                <FaInstagramSquare />
              </a>
            </span>
            <span className="bannerIcon" title="Whatsapp">
              <a href="https://wa.me/491603779642" target="_blank" rel="noreferrer">
                <FaWhatsappSquare />
              </a>
            </span>
            <span className="bannerIcon" title="LinkedIn">
              <a href="https://www.linkedin.com/in/golikandrii/" target="_blank" rel="noreferrer">
                <FaLinkedin />
              </a>
            </span>
          </div>
          <div className="flex gap-4 mt-6">
            <p>DOWNLOAD CV</p>
            <div>
                <a className="download" href="/cv/Golik_En.pdf" download>
                EN
              </a>
              <a className="download" href="/cv/Golik_De.pdf" download>
                DE
              </a>
            </div>
          </div>
        </div>
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            BEST SKILL ON
          </h2>
          <div className="flex gap-4">
            <span className="bannerIcon" title="HTML5">
              <FaHtml5 />
            </span>
            <span className="bannerIcon" title="CSS3">
              <FaCss3 />
            </span>
            <span className="bannerIcon" title="JavaScript">
              <FaJsSquare />
            </span>
            <span className="bannerIcon" title="React">
              <FaReact />
            </span>
          </div>
          <div className="flex gap-4">
            <span className="bannerIcon" title="Git">
              <FaGithub />
            </span>
            <span className="bannerIcon" title="Figma">
              <FaFigma />
            </span>
            <span className="bannerIcon" title="Wordpress">
              <FaWordpress />
            </span>
            <span className="bannerIcon" title="Joomla">
              <FaJoomla />
            </span>
          </div>
        </div>
      </div>
  )
}

export default Media