import React from 'react'
import {
  FaTelegram,
  FaInstagramSquare,
  FaWhatsappSquare,
  FaLinkedin
} from "react-icons/fa";
import { contactImg } from "../../assets/index";

const ContactLeft = () => {
  return (
    <div className="w-full lgl:w-[100%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-4 lgl:p-8 rounded-lg shadow-shadowOne flex flex-col gap-8 justify-center">
      <img
        className="h-auto max-w-full object-cover rounded-lg mb-2"
        src={contactImg}
        alt="contactImg"
      />
      <div className="flex flex-col gap-4">
        <h3 className="text-3xl font-bold text-white">Andrii Golik</h3>
        <p className="text-base text-gray-400 flex items-center gap-2">
          Phone: <span className="text-lightText">+49(160) 377-96-42</span>
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2">
          Email: <span className="text-lightText">golik.andrii@gmail.com</span>
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="text-base uppercase font-titleFont mb-4">Find me in</h2>
        <div className="flex gap-4">
            <span className="bannerIcon" title="Telegram">
              <a href="https://t.me/golikandrii" target="_blank" rel="noreferrer">
                <FaTelegram />
              </a>
            </span>
          <span className="bannerIcon" title="Instagram">
              <a href="https://www.instagram.com/golikandrey/" target="_blank" rel="noreferrer">
                <FaInstagramSquare />
              </a>
            </span>
          <span className="bannerIcon" title="Whatsapp">
              <a href="https://wa.me/491603779642" target="_blank" rel="noreferrer">
                <FaWhatsappSquare />
              </a>
            </span>
          <span className="bannerIcon" title="LinkedIn">
              <a href="https://www.linkedin.com/in/golikandrii/" target="_blank" rel="noreferrer">
                <FaLinkedin />
              </a>
            </span>
        </div>
      </div>
    </div>
  );
}

export default ContactLeft