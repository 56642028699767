import React from 'react'
// import emailjs from '@emailjs/browser';
import Title from '../layouts/Title';
import ContactLeft from './ContactLeft';

const Contact = () => {


  // const [username, setUsername] = useState("");
  // const [email, setEmail] = useState("");
  // const [message, setMessage] = useState("");
  // const [errMsg, setErrMsg] = useState("");
  // const [successMsg, setSuccessMsg] = useState("");

  // ========== Email Validation start here ==============
  // const emailValidation = () => {
  //   return String(email)
  //     .toLocaleLowerCase()
  //     .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  // };
  // ========== Email Validation end here ================

  // const handleSend = (e) => {
  //   e.preventDefault();
  //   if (username === "") {
  //     setErrMsg("Username is required!");
  //   } else if (email === "") {
  //     setErrMsg("Please give your Email!");
  //   } else if (!emailValidation(email)) {
  //     setErrMsg("Give a valid Email!");
  //   } else if (message === "") {
  //     setErrMsg("Message is required!");
  //   } else {
  //     setSuccessMsg(
  //       `Thank you dear ${username}, Your Messages has been sent Successfully!`
  //     );
  //     setErrMsg("");
  //     setUsername("");
  //     setEmail("");
  //     setMessage("");
  //   }
  // };
  return (
    <section
      id="contact"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title title="CONTACT" des="Get in touch" />
      </div>
      <div className="w-full">
        <div className="w-full h-auto flex flex-col lgl:flex-row justify-between">
          <ContactLeft />

        </div>
      </div>
    </section>
  );
}

export default Contact